
















































import { Component, Watch } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import IconPlay from '@/assets/img/icons/play.svg?sprite'
import Color from '@/constants/color'
import MediaContent from '@/components/MediaContent.vue'
import KeyFigures from '@/components/KeyFigures.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import BasicIntersectionObservable from '@/mixins/BasicIntersectionObservable'
import gsap from 'gsap'
import CheatSheet from '@/components/CheatSheet.vue'
import MutationRemoteType from '@/constants/mutation-remote-type'
import ModuleNamespace from '@/constants/module-namespace'

@Component({
    components: {
        ScrollerElement,
        IconPlay,
        MediaContent,
        ButtonIcon,
        KeyFigures,
        ArrowDownNextSlide,
        CheatSheet
    }
})
export default class SlideMedia extends mixins(Slide, BasicIntersectionObservable) {
    @State popinVideoIsOpen!: boolean
    @State hasPresentingLeader!: boolean

    @Getter navigationIsAllowed!: boolean

    colorButton = Color.LIGHT

    $refs!: {
        background: MediaContent
        title: HTMLElement
        play: ButtonIcon
    }

    get backgroundMedia(): RoadizDocument | undefined {
        return this.walkerItem.media?.[0]
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemMedia
    }

    get hasKeyFigures() {
        return (
            (this.walkerItem.overTitle && this.walkerItem.overTitle.length) ||
            (this.walkerItem.title && this.walkerItem.title.length)
        )
    }

    // get isJson(): boolean {
    //     return this.walkerItem.media.length > 0 && this.walkerItem.media[0].mimeType === 'application/json'
    // }

    get isVideo(): boolean {
        return (
            this.walkerItem.fullscreenMedia.length > 0 &&
            ((this.walkerItem.fullscreenMedia[0].embedId &&
                this.walkerItem.fullscreenMedia[0].embedPlatform === 'vimeo') ||
                this.walkerItem.fullscreenMedia[0].mimeType === 'video/mp4')
        )
    }

    toggleModalVideo(): void {
        if (!this.navigationIsAllowed) return

        this.$store.commit(
            ModuleNamespace.REMOTE + '/' + MutationRemoteType.POPIN_DOCUMENT,
            this.walkerItem.fullscreenMedia[0]
        )
    }

    populateAppear(timeline: GSAPTimeline) {
        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 2.4,
                    ease: 'power2'
                },
                0.5
            )
        }

        if (this.$refs.title) {
            timeline.fromTo(
                this.$refs.title,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.2,
                    ease: 'power3'
                },
                0.8
            )
        }

        if (this.$refs.play) {
            gsap.killTweensOf(this.$refs.play.$el) // kill the enterY tween

            timeline.fromTo(
                this.$refs.play.$el,
                {
                    scale: 0
                },
                {
                    scale: 1,
                    duration: 0.8,
                    ease: 'power3'
                },
                0.9
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    ease: 'none'
                },
                0
            )
        }

        if (this.$refs.title) {
            timeline.fromTo(
                this.$refs.title,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: 'none'
                },
                0.5
            )
        }

        if (this.$refs.play) {
            timeline
                .set(
                    this.$refs.play.$el,
                    {
                        scale: 0
                    },
                    0.25
                )
                .call(
                    () => {
                        gsap.to(this.$refs.play.$el, {
                            scale: 1,
                            duration: 0.8,
                            ease: 'power3'
                        })
                    },
                    [],
                    0.8
                )
        }
    }

    onObservableEnter() {
        if (this.$refs.background?.isLottie) this.$refs.background.play()
    }

    onObservableLeave() {
        if (this.$refs.background?.isLottie) this.$refs.background.stop()
    }

    @Watch('walker', { immediate: true })
    onWalkerChange() {
        this.color = this.walkerItem.invertContrast ? Color.DARK : Color.LIGHT
    }
}
