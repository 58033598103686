var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scroller-element',{ref:"scrollerElement",attrs:{"index-y":_vm.index,"y":_vm.y}},[_c('section',{staticClass:"slide",class:[
            _vm.$style.root,
            // { [$style.rootNoFullscreen]: walkerItem.fullscreenMedia.length === 0 || isJson },
            ( _obj = {}, _obj[_vm.$style.rootNoContent] = !_vm.walkerItem.overTitle && !_vm.walkerItem.title, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.rootIsVideo] = _vm.isVideo, _obj$1 ),
            ( _obj$2 = {}, _obj$2[_vm.$style.rootInvertContrast] = _vm.walkerItem.invertContrast, _obj$2 )
        ]},[(_vm.backgroundMedia)?_c('media-content',{ref:"background",class:_vm.$style.background,attrs:{"media":_vm.backgroundMedia,"fullscreen":""}}):_vm._e(),(_vm.hasKeyFigures)?_c('key-figures',{attrs:{"content":_vm.walkerItem.overTitle,"invertContrast":_vm.walkerItem.invertContrast}},[(_vm.walkerItem.title)?_c('h1',{ref:"title",staticClass:"text-h2",class:_vm.$style.title,on:{"click":_vm.toggleModalVideo}},[_vm._v(" "+_vm._s(_vm.walkerItem.title)+" ")]):_vm._e(),(_vm.navigationIsAllowed && _vm.isVideo)?_c('button-icon',{ref:"play",class:_vm.$style.playButton,attrs:{"color":_vm.colorButton,"hover":"fill","large":""},nativeOn:{"click":function($event){return _vm.toggleModalVideo.apply(null, arguments)}}},[_c('icon-play')],1):_vm._e()],1):_vm._e(),(!_vm.isLast)?_c('arrow-down-next-slide',{attrs:{"color":_vm.color}}):_vm._e(),_c('cheat-sheet',{attrs:{"content":_vm.walkerItem.cheatSheet}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }